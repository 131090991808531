@media (max-width: 1024px) {
  .contactEmail {
    font-size: 14px !important;
  }

  .companyBtn {
    width: 60%;
    transform: translate(30%, 90px);
  }

  .company:hover .companyBtn {
    transform: translate(30%, 0px);
  }
}

@media (max-width: 1000px) {
  #about img {
    transform: translateY(0%);
  }

  .contactEmail {
    font-size: 18px !important;
  }
}

@media (max-width: 768px) {
  #about img {
    margin: 50px 0;
  }

  .aboutTop {
    flex-direction: column;
  }

  .homePage>#about>.overlay>.container>.row>.aboutImgWrapper>video {
    margin-bottom: 50px;
  }
}

@media (max-width: 430px) {
  #menu.navbar-default .navbar-nav>.dropdown>.dropdown-menu {
    background: #E7E7E7;
    width: 90%;
    margin-inline: auto;
  }
  #menu.navbar-default .navbar-nav>.dropdown>.dropdown-menu>li>a {
    margin: 10px 20px;
  }

  .intro h1 {
    font-size: 50px;
  }

  .intro h3 {
    font-size: 25px;
    letter-spacing: 3px;
  }

  .intro h4 {
    font-size: 20px;
    white-space: pre-wrap;
  }


  .counterNumber h1 {
    font-size: 20px;
  }

  .counterTitle>h3 {
    font-size: 15px;
  }

  .servicePage>#header>.intro>.overlay>.container>.row>.intro-text>.headerName>.parallax-effect-glare-scale,
  .individualCompany>#header>.intro>.overlay>.container>.row>.intro-text>.headerName>.parallax-effect-glare-scale {
    bottom: -140px;
  }

  .servicePage>#header>.intro>.overlay>.container>.row>.intro-text>.headerName>.parallax-effect-glare-scale>h1,
  .individualCompany>#header>.intro>.overlay>.container>.row>.intro-text>.headerName>.parallax-effect-glare-scale>h1 {
    font-size: 20px;
  }

  .client-image {
    padding: 2px;
  }
}

@media screen and (max-width: 400px) {
  #about,
  #services,
  #companies,
  #clients,
  #management,
  #contact,
  #footer {
    width: 111%;
  }

  #header,
  .intro video {
    width: 111%;
    height: 111%;
    object-fit: cover;
  }

  .servicePage>#header>.intro,
  .individualCompany>#header>.intro {
    height: 100%;
  }
}

@media screen and (max-width: 350px) {
  #about,
  #services,
  #companies,
  #clients,
  #management,
  #contact,
  #footer {
    width: 121%;
  }

  #header,
  .intro video {
    width: 121%;
    height: 121%;
    object-fit: cover;
  }


  .servicePage>#header>.intro,
  .individualCompany>#header>.intro {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .counterNumber h1 {
    font-size: 18px;
  }

  .counterTitle>h3 {
    font-size: 15px;
  }

  .contactEmail {
    font-size: 16px !important;
  }
}